.App {
    height: 100vh;
    /* width: 1280px; */
    width: 100%;
    /* min-height: 100%; */
    image-rendering: pixelated;
    overflow-y: hidden;
    overflow-x: hidden;
}

html,
body {
    height: 100%;
    margin: 0;
    overflow: hidden;
}

div {
    display: flex;
}

#root {
    height: 100%;
}

.white {
    color: #fff;
}
